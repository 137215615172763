import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Trash2, ChevronDown, ChevronUp } from 'lucide-react';
import PageIllustration from './PageIllustration';
import axios from 'axios';

function GenerateOutline() {
  const navigate = useNavigate();
  const [outline, setOutline] = useState({
    title: '',
    intro: '',
    targetAudience: '',
    outline: {
      H2Sections: [{
        H2Title: '',
        ctaButton: '',
        H3Sections: [{
          H3Title: '',
          notes: '',
          content: ''
        }],
        sectionImage: {
          id: '',
          url: ''
        }
      }]
    },
    faq: [{
      question: '',
      answer: ''
    }],
    metadata: {
      createdAt: new Date(),
      updatedAt: new Date(),
      createdBy: null
    }
  });

  const [expandedSections, setExpandedSections] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post('/api/outlines/new', outline);
      const { id } = response.data; // Assuming your API returns the new outline's ID
      navigate(`/outlines/${id}`);
    } catch (error) {
      console.error('Error creating outline:', error);
      setError('アウトラインの作成に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  const handleTitleChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 60) {
      setOutline({ ...outline, title: newValue });
    }
  };

  const handleIntroChange = (e) => {
    setOutline({ ...outline, intro: e.target.value });
  };

  const handleTargetAudienceChange = (e) => {
    setOutline({ ...outline, targetAudience: e.target.value });
  };

  const handleH2Change = (index, value) => {
    const newSections = [...outline.outline.H2Sections];
    newSections[index].H2Title = value;
    setOutline({
      ...outline,
      outline: { ...outline.outline, H2Sections: newSections }
    });
  };

  const handleCtaButtonChange = (index, value) => {
    const newSections = [...outline.outline.H2Sections];
    newSections[index].ctaButton = value;
    setOutline({
      ...outline,
      outline: { ...outline.outline, H2Sections: newSections }
    });
  };

  const handleH3Change = (sectionIndex, h3Index, field, value) => {
    const newSections = [...outline.outline.H2Sections];
    newSections[sectionIndex].H3Sections[h3Index][field] = value;
    setOutline({
      ...outline,
      outline: { ...outline.outline, H2Sections: newSections }
    });
  };

  const handleFAQChange = (index, field, value) => {
    const newFAQ = [...outline.faq];
    newFAQ[index][field] = value;
    setOutline({ ...outline, faq: newFAQ });
  };

  const addSection = () => {
    setOutline({
      ...outline,
      outline: {
        ...outline.outline,
        H2Sections: [...outline.outline.H2Sections, {
          H2Title: '',
          ctaButton: '',
          H3Sections: [{
            H3Title: '',
            notes: '',
            content: ''
          }],
          sectionImage: {
            id: '',
            url: ''
          }
        }]
      }
    });
    setExpandedSections(prev => ({
      ...prev,
      [outline.outline.H2Sections.length]: true
    }));
  };

  const addH3Section = (sectionIndex) => {
    const newSections = [...outline.outline.H2Sections];
    newSections[sectionIndex].H3Sections.push({
      H3Title: '',
      notes: '',
      content: ''
    });
    setOutline({
      ...outline,
      outline: { ...outline.outline, H2Sections: newSections }
    });
  };

  const addFAQ = () => {
    setOutline({
      ...outline,
      faq: [...outline.faq, { question: '', answer: '' }]
    });
  };

  const removeSection = (index) => {
    if (outline.outline.H2Sections.length > 1) {
      const newSections = outline.outline.H2Sections.filter((_, i) => i !== index);
      setOutline({
        ...outline,
        outline: { ...outline.outline, H2Sections: newSections }
      });
      const newExpandedSections = { ...expandedSections };
      delete newExpandedSections[index];
      setExpandedSections(newExpandedSections);
    }
  };

  const removeH3Section = (sectionIndex, h3Index) => {
    if (outline.outline.H2Sections[sectionIndex].H3Sections.length > 1) {
      const newSections = [...outline.outline.H2Sections];
      newSections[sectionIndex].H3Sections = newSections[sectionIndex].H3Sections.filter((_, i) => i !== h3Index);
      setOutline({
        ...outline,
        outline: { ...outline.outline, H2Sections: newSections }
      });
    }
  };

  const removeFAQ = (index) => {
    if (outline.faq.length > 1) {
      const newFAQ = outline.faq.filter((_, i) => i !== index);
      setOutline({ ...outline, faq: newFAQ });
    }
  };

  const toggleSection = (index) => {
    setExpandedSections(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const renderSection = (section, sectionIndex) => {
    const isExpanded = expandedSections[sectionIndex] !== false;

    return (
      <div key={sectionIndex} className="mb-4 overflow-hidden rounded-2xl bg-white/90 shadow-lg backdrop-blur-sm">
        <div className="border-b border-gray-200 p-4">
          <div className="flex items-center justify-between">
            <div className="flex-1 pr-4">
              <input
                type="text"
                placeholder="H2 HEADING"
                value={section.H2Title}
                onChange={(e) => handleH2Change(sectionIndex, e.target.value)}
                className="w-full rounded-xl bg-transparent px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
              />
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={() => toggleSection(sectionIndex)}
                className="rounded-xl p-2 text-gray-500 hover:bg-gray-100"
              >
                {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </button>
              <button
                type="button"
                onClick={() => removeSection(sectionIndex)}
                className="rounded-xl p-2 text-red-500 hover:bg-red-50 disabled:opacity-50"
                disabled={outline.outline.H2Sections.length <= 1}
              >
                <Trash2 size={20} />
              </button>
            </div>
          </div>
        </div>

        {isExpanded && (
          <div className="p-4">
            <div className="mb-4">
              <input
                type="text"
                placeholder="CALL TO ACTION TEXT WITH ▷"
                value={section.ctaButton}
                onChange={(e) => handleCtaButtonChange(sectionIndex, e.target.value)}
                className="w-full rounded-xl bg-white px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
              />
            </div>

            {section.H3Sections.map((h3Section, h3Index) => (
              <div key={h3Index} className="mb-4 rounded-xl bg-gray-50 p-4">
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="H3 HEADING"
                    value={h3Section.H3Title}
                    onChange={(e) => handleH3Change(sectionIndex, h3Index, 'H3Title', e.target.value)}
                    className="w-full rounded-xl bg-white px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                  />
                </div>
                <div className="mb-4">
                  <textarea
                    placeholder="NOTES"
                    value={h3Section.notes}
                    onChange={(e) => handleH3Change(sectionIndex, h3Index, 'notes', e.target.value)}
                    rows="2"
                    className="w-full rounded-xl bg-white px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                  />
                </div>
                <div className="mb-2">
                  <textarea
                    placeholder="CONTENT"
                    value={h3Section.content}
                    onChange={(e) => handleH3Change(sectionIndex, h3Index, 'content', e.target.value)}
                    rows="4"
                    className="w-full rounded-xl bg-white px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => removeH3Section(sectionIndex, h3Index)}
                    className="rounded-xl p-2 text-red-500 hover:bg-red-50 disabled:opacity-50"
                    disabled={section.H3Sections.length <= 1}
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addH3Section(sectionIndex)}
              className="inline-flex items-center rounded-xl bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
            >
              <Plus size={16} className="mr-2" />
              小見出しを追加
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-5xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          <div className="mb-8 text-center">
            <h1 className="mb-4 text-3xl font-bold tracking-[-0.037em] text-gray-800 md:text-4xl lg:text-5xl">
              アウトラインの作成
            </h1>
            <p className="text-lg text-gray-600">
              記事の構成を詳細に設定できます
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm">
              <label className="mb-2 block text-sm font-medium text-gray-700">メインタイトル</label>
              <input
                type="text"
                value={outline.title}
                onChange={handleTitleChange}
                maxLength={60}
                className="w-full rounded-xl bg-transparent px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                placeholder="TITLE"
              />
              <div className="mt-1 text-right text-sm text-gray-500">
                {outline.title.length}/60文字
              </div>
            </div>

            <div className="rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm">
              <label className="mb-2 block text-sm font-medium text-gray-700">導入文</label>
              <textarea
                value={outline.intro}
                onChange={handleIntroChange}
                rows="4"
                className="w-full rounded-xl bg-transparent px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                placeholder="INTRODUCTION TEXT"
              />
            </div>

            <div className="rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm">
              <label className="mb-2 block text-sm font-medium text-gray-700">ターゲット読者</label>
              <input
                type="text"
                value={outline.targetAudience}
                onChange={handleTargetAudienceChange}
                className="w-full rounded-xl bg-transparent px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                placeholder="TARGET AUDIENCE"
              />
            </div>

            <div className="space-y-4">
              {outline.outline.H2Sections.map((section, index) => renderSection(section, index))}
              <button
                type="button"
                onClick={addSection}
                className="inline-flex w-full items-center justify-center rounded-xl bg-gray-800 px-6 py-3 text-sm font-medium text-white hover:bg-gray-700"
              >
                <Plus size={20} className="mr-2" />
                新しいセクションを追加
              </button>
            </div>

            <div className="rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm">
              <h2 className="mb-4 text-xl font-bold text-gray-800">よくある質問</h2>
              <div className="space-y-4">
                {outline.faq.map((faq, index) => (
                  <div key={index} className="rounded-xl bg-gray-50 p-4">
                    <input
                      type="text"
                      placeholder="COMMON QUESTION"
                      value={faq.question}
                      onChange={(e) => handleFAQChange(index, 'question', e.target.value)}
                      className="mb-2 w-full rounded-xl bg-white px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                    />
                    <textarea
                      placeholder="DETAILED ANSWER"
                      value={faq.answer}
                      onChange={(e) => handleFAQChange(index, 'answer', e.target.value)}
                      rows="4"
                      className="mb-2 w-full rounded-xl bg-white px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                    />
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => removeFAQ(index)}
                        className="rounded-xl p-2 text-red-500 hover:bg-red-50 disabled:opacity-50"
                        disabled={outline.faq.length <= 1}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addFAQ}
                  className="inline-flex items-center rounded-xl bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                >
                  <Plus size={16} className="mr-2" />
                  FAQを追加
                </button>
              </div>
            </div>

            {error && (
              <div className="rounded-xl bg-red-50 p-4 text-center text-red-600">
                {error}
              </div>
            )}

            <div className="flex justify-center">
              <button
                type="submit"
                disabled={loading}
                className="inline-flex items-center rounded-xl bg-gradient-to-t from-gray-800 to-gray-700 px-8 py-3 text-white shadow-sm hover:to-gray-600 disabled:opacity-50"
              >
                {loading ? (
                  <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
                ) : (
                  'アウトラインを作成'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default GenerateOutline;