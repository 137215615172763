import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PageIllustration from './PageIllustration';
import { Plus, Trash2, ChevronDown, ChevronUp } from 'lucide-react';

function EditOutline() {
  const [outline, setOutline] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOutline = async () => {
      try {
        const response = await axios.get(`/api/outlines/${id}`);
        setOutline(response.data);
        // Initialize all sections as expanded
        const initialExpanded = {};
        response.data.outline.H2Sections.forEach((_, index) => {
          initialExpanded[index] = true;
        });
        setExpandedSections(initialExpanded);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching outline:', error);
        setLoading(false);
      }
    };

    fetchOutline();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      // Create a properly structured outline object
      const updatedOutline = {
        title: outline.title || '',
        intro: outline.intro || '',
        targetAudience: outline.targetAudience || '',
        outline: {
          H2Sections: outline.outline.H2Sections.map(section => ({
            H2Title: section.H2Title || '',
            ctaButton: section.ctaButton || '',
            H3Sections: section.H3Sections.map(h3 => ({
              H3Title: h3.H3Title || '',
              notes: h3.notes || '',
              content: h3.content || ''
            })),
            sectionImage: section.sectionImage || {
              id: '',
              url: ''
            }
          }))
        },
        faq: outline.faq.map(item => ({
          question: item.question || '',
          answer: item.answer || ''
        })),
        metadata: {
          createdAt: outline.metadata.createdAt, // Keep the original string format
          createdBy: outline.metadata.createdBy,
          updatedAt: new Date().toISOString() // Send as ISO string
        }
      };

      await axios.put(`/api/outlines/${id}`, updatedOutline);
      navigate(`/outlines/${id}`);
    } catch (error) {
      console.error('Error updating outline:', error);
      alert('アウトラインの更新に失敗しました');
    } finally {
      setSaving(false);
    }
  };

  const handleTitleChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 60) {
      setOutline({ ...outline, title: newValue });
    }
  };

  const handleIntroChange = (e) => {
    setOutline({ ...outline, intro: e.target.value });
  };

  const handleTargetAudienceChange = (e) => {
    setOutline({ ...outline, targetAudience: e.target.value });
  };

  const handleH2Change = (index, value) => {
    const newSections = [...outline.outline.H2Sections];
    newSections[index].H2Title = value;
    setOutline({
      ...outline,
      outline: { ...outline.outline, H2Sections: newSections }
    });
  };

  const handleCtaButtonChange = (index, value) => {
    const newSections = [...outline.outline.H2Sections];
    newSections[index].ctaButton = value;
    setOutline({
      ...outline,
      outline: { ...outline.outline, H2Sections: newSections }
    });
  };

  const handleH3Change = (sectionIndex, h3Index, field, value) => {
    const newSections = [...outline.outline.H2Sections];
    newSections[sectionIndex].H3Sections[h3Index][field] = value;
    setOutline({
      ...outline,
      outline: { ...outline.outline, H2Sections: newSections }
    });
  };

  const handleFAQChange = (index, field, value) => {
    const newFAQ = [...outline.faq];
    newFAQ[index][field] = value;
    setOutline({ ...outline, faq: newFAQ });
  };

  const addSection = () => {
    setOutline({
      ...outline,
      outline: {
        ...outline.outline,
        H2Sections: [...outline.outline.H2Sections, {
          H2Title: '',
          ctaButton: '',
          H3Sections: [{
            H3Title: '',
            notes: '',
            content: ''
          }],
          sectionImage: {
            id: '',
            url: ''
          }
        }]
      }
    });
    setExpandedSections(prev => ({
      ...prev,
      [outline.outline.H2Sections.length]: true
    }));
  };

  const addH3Section = (sectionIndex) => {
    const newSections = [...outline.outline.H2Sections];
    newSections[sectionIndex].H3Sections.push({
      H3Title: '',
      notes: '',
      content: ''
    });
    setOutline({
      ...outline,
      outline: { ...outline.outline, H2Sections: newSections }
    });
  };

  const addFAQ = () => {
    setOutline({
      ...outline,
      faq: [...outline.faq, { question: '', answer: '' }]
    });
  };

  const removeSection = (index) => {
    if (outline.outline.H2Sections.length > 1) {
      const newSections = outline.outline.H2Sections.filter((_, i) => i !== index);
      setOutline({
        ...outline,
        outline: { ...outline.outline, H2Sections: newSections }
      });
      const newExpandedSections = { ...expandedSections };
      delete newExpandedSections[index];
      setExpandedSections(newExpandedSections);
    }
  };

  const removeH3Section = (sectionIndex, h3Index) => {
    if (outline.outline.H2Sections[sectionIndex].H3Sections.length > 1) {
      const newSections = [...outline.outline.H2Sections];
      newSections[sectionIndex].H3Sections = newSections[sectionIndex].H3Sections.filter((_, i) => i !== h3Index);
      setOutline({
        ...outline,
        outline: { ...outline.outline, H2Sections: newSections }
      });
    }
  };

  const removeFAQ = (index) => {
    if (outline.faq.length > 1) {
      const newFAQ = outline.faq.filter((_, i) => i !== index);
      setOutline({ ...outline, faq: newFAQ });
    }
  };

  const toggleSection = (index) => {
    setExpandedSections(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-800 border-t-transparent"></div>
      </div>
    );
  }

  if (!outline) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <p className="text-gray-600">アウトラインが見つかりませんでした。</p>
      </div>
    );
  }

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-4xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Title Section */}
            <div className="overflow-hidden rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm">
              <h1 className="mb-6 text-3xl font-bold tracking-[-0.037em] text-gray-800">
                アウトラインを編集
              </h1>
              
              <div className="space-y-4">
                <div>
                  <label className="mb-2 block text-sm font-medium text-gray-700">
                    メインタイトル
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={outline.title}
                      onChange={handleTitleChange}
                      maxLength={60}
                      className="w-full rounded-xl bg-transparent px-4 py-3 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                      placeholder="メインタイトルを入力"
                    />
                    <div className="mt-1 text-right text-sm text-gray-500">
                      {outline.title.length}/60文字
                    </div>
                  </div>
                </div>

                <div>
                  <label className="mb-2 block text-sm font-medium text-gray-700">
                    導入文
                  </label>
                  <textarea
                    value={outline.intro}
                    onChange={handleIntroChange}
                    rows="4"
                    className="w-full rounded-xl bg-transparent px-4 py-3 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                    placeholder="アウトラインの導入文を入力"
                  />
                </div>

                <div>
                  <label className="mb-2 block text-sm font-medium text-gray-700">
                    ターゲット読者
                  </label>
                  <input
                    type="text"
                    value={outline.targetAudience}
                    onChange={handleTargetAudienceChange}
                    className="w-full rounded-xl bg-transparent px-4 py-3 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                    placeholder="ターゲット読者を入力"
                  />
                </div>
              </div>
            </div>

            {/* Sections */}
            {outline.outline.H2Sections.map((section, sectionIndex) => (
              <div
                key={sectionIndex}
                className="overflow-hidden rounded-2xl bg-white/90 shadow-lg backdrop-blur-sm"
              >
                <div className="border-b border-gray-200 p-4">
                  <div className="flex items-center justify-between">
                    <div className="flex-1 pr-4">
                      <input
                        type="text"
                        placeholder={`セクション ${sectionIndex + 1} のタイトル`}
                        value={section.H2Title}
                        onChange={(e) => handleH2Change(sectionIndex, e.target.value)}
                        className="w-full rounded-xl bg-transparent px-4 py-3 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        type="button"
                        onClick={() => toggleSection(sectionIndex)}
                        className="rounded-xl p-2 text-gray-500 hover:bg-gray-100"
                      >
                        {expandedSections[sectionIndex] ? (
                          <ChevronUp size={20} />
                        ) : (
                          <ChevronDown size={20} />
                        )}
                      </button>
                      <button
                        type="button"
                        onClick={() => removeSection(sectionIndex)}
                        className="rounded-xl p-2 text-red-500 hover:bg-red-50"
                        disabled={outline.outline.H2Sections.length <= 1}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                </div>

                {expandedSections[sectionIndex] && (
                  <div className="p-4">
                    <div className="mb-4">
                      <input
                        type="text"
                        placeholder="CTAボタンテキスト"
                        value={section.ctaButton || ''}
                        onChange={(e) => handleCtaButtonChange(sectionIndex, e.target.value)}
                        className="w-full rounded-xl bg-white px-4 py-3 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                      />
                    </div>
                    {section.H3Sections.map((h3Section, h3Index) => (
                      <div key={h3Index} className="mb-4 rounded-xl bg-gray-50 p-4">
                        <input
                          type="text"
                          placeholder={`小見出し ${h3Index + 1}`}
                          value={h3Section.H3Title}
                          onChange={(e) => handleH3Change(sectionIndex, h3Index, 'H3Title', e.target.value)}
                          className="mb-3 w-full rounded-xl bg-white px-4 py-3 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                        />
                        <textarea
                          placeholder="キーポイント"
                          value={h3Section.notes}
                          onChange={(e) => handleH3Change(sectionIndex, h3Index, 'notes', e.target.value)}
                          rows="2"
                          className="mb-3 w-full rounded-xl bg-white px-4 py-3 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                        />
                        <textarea
                          placeholder="コンテンツ"
                          value={h3Section.content}
                          onChange={(e) => handleH3Change(sectionIndex, h3Index, 'content', e.target.value)}
                          rows="4"
                          className="mb-3 w-full rounded-xl bg-white px-4 py-3 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                        />
                        <div className="flex justify-end">
                          <button
                            type="button"
                            onClick={() => removeH3Section(sectionIndex, h3Index)}
                            className="rounded-xl p-2 text-red-500 hover:bg-red-50"
                            disabled={section.H3Sections.length <= 1}
                          >
                            <Trash2 size={20} />
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addH3Section(sectionIndex)}
                      className="inline-flex items-center rounded-xl bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                    >
                      <Plus size={16} className="mr-2" />
                      小見出しを追加
                    </button>
                  </div>
                )}
              </div>
            ))}

            <button
              type="button"
              onClick={addSection}
              className="inline-flex w-full items-center justify-center rounded-xl bg-gray-800 px-6 py-3 text-sm font-medium text-white hover:bg-gray-700"
            >
              <Plus size={20} className="mr-2" />
              新しいセクションを追加
            </button>

            {/* FAQ Section */}
            <div className="overflow-hidden rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm">
              <h2 className="mb-6 text-2xl font-bold text-gray-800">
                よくある質問
              </h2>
              <div className="space-y-4">
                {outline.faq.map((faq, index) => (
                  <div key={index} className="rounded-xl bg-gray-50 p-4">
                    <input
                      type="text"
                      placeholder={`質問 ${index + 1}`}
                      value={faq.question}
                      onChange={(e) => handleFAQChange(index, 'question', e.target.value)}
                      className="mb-3 w-full rounded-xl bg-white px-4 py-3 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                    />
                    <textarea
                      placeholder="回答"
                      value={faq.answer}
                      onChange={(e) => handleFAQChange(index, 'answer', e.target.value)}
                      rows="4"
                      className="mb-3 w-full rounded-xl bg-white px-4 py-3 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                    />
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => removeFAQ(index)}
                        className="rounded-xl p-2 text-red-500 hover:bg-red-50"
                        disabled={outline.faq.length <= 1}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addFAQ}
                  className="inline-flex items-center rounded-xl bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                >
                  <Plus size={16} className="mr-2" />
                  FAQを追加
                </button>
              </div>
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={saving}
                className="inline-flex items-center rounded-xl bg-gradient-to-t from-gray-800 to-gray-700 px-8 py-3 text-white shadow-sm hover:to-gray-600 disabled:opacity-50"
              >
                {saving ? (
                  <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
                ) : (
                  '変更を保存'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default EditOutline;