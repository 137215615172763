import React from 'react';

const ContentParser = ({ text }) => {
  if (!text) return null;

  // Function to parse both <b> tags and ** markers for bold text
  const parseBoldText = (text) => {
    // First split by <b> tags
    const parts = text.split(/(<b>.*?<\/b>)/g);
    
    return parts.map((part, index) => {
      // Handle <b> tags
      if (part.startsWith('<b>') && part.endsWith('</b>')) {
        return (
          <strong key={index} className="font-bold">
            {part.slice(3, -4)}
          </strong>
        );
      }
      
      // Handle ** markers in remaining text
      const subParts = part.split(/(\*\*.*?\*\*)/g);
      return subParts.map((subPart, subIndex) => {
        if (subPart.startsWith('**') && subPart.endsWith('**')) {
          return (
            <strong key={`${index}-${subIndex}`} className="font-bold">
              {subPart.slice(2, -2)}
            </strong>
          );
        }
        return subPart;
      });
    });
  };

  // Function to detect and parse pipe tables
  const detectAndParseTables = (text) => {
    // Split text into lines
    const lines = text.split('\n');
    const tables = [];
    let currentTable = [];
    let isInTable = false;
    let normalText = [];

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      
      // Check if line is part of a table
      if (line.startsWith('|') && line.endsWith('|')) {
        if (!isInTable) {
          isInTable = true;
          // If we have normal text, add it
          if (normalText.length > 0) {
            tables.push({ type: 'text', content: normalText.join('\n') });
            normalText = [];
          }
        }
        currentTable.push(line);
      } else {
        if (isInTable) {
          // End of table
          tables.push({ type: 'table', content: currentTable.join('\n') });
          currentTable = [];
          isInTable = false;
        }
        if (line) {
          normalText.push(line);
        }
      }
    }

    // Handle any remaining content
    if (currentTable.length > 0) {
      tables.push({ type: 'table', content: currentTable.join('\n') });
    }
    if (normalText.length > 0) {
      tables.push({ type: 'text', content: normalText.join('\n') });
    }

    return tables;
  };

  // Function to parse pipe table content
  const parsePipeTable = (tableContent) => {
    const lines = tableContent
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.startsWith('|') && line.endsWith('|'));

    if (lines.length < 3) return null; // Need header, separator, and at least one data row

    // Process each line into cells
    const rows = lines.map(line => {
      return line
        .slice(1, -1) // Remove outer pipes
        .split('|')
        .map(cell => cell.trim());
    });

    // Filter out separator row (the one with dashes)
    const dataRows = rows.filter(row => !row.every(cell => /^-+$/.test(cell)));
    
    // Calculate equal column widths
    const numColumns = dataRows[0].length;
    const columnWidth = `${100 / numColumns}%`;

    return (
      <div className="overflow-x-auto my-4">
        <table className="min-w-full table-fixed divide-y divide-gray-200">
          <tbody className="divide-y divide-gray-200">
            {dataRows.map((row, rowIndex) => (
              <tr key={rowIndex} className={rowIndex === 0 ? "bg-gray-50" : "bg-white"}>
                {row.map((cell, cellIndex) => {
                  const cellProps = {
                    key: cellIndex,
                    style: { width: columnWidth },
                    className: `px-6 py-4 text-sm ${
                      rowIndex === 0
                        ? "font-medium text-gray-900"
                        : "text-gray-500"
                    } whitespace-pre-wrap`
                  };

                  return rowIndex === 0 ? (
                    <th {...cellProps}>{parseBoldText(cell)}</th>
                  ) : (
                    <td {...cellProps}>{parseBoldText(cell)}</td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Process the content
  const sections = detectAndParseTables(text);

  return (
    <div className="space-y-4">
      {sections.map((section, index) => {
        if (section.type === 'table') {
          return <React.Fragment key={index}>{parsePipeTable(section.content)}</React.Fragment>;
        }

        // Handle regular text content
        const paragraphs = section.content.split('\n\n');
        return paragraphs.map((paragraph, pIndex) => {
          if (!paragraph.trim()) return null;

          if (paragraph.startsWith('#### ')) {
            return (
              <h4 key={`${index}-${pIndex}`} className="text-lg font-medium text-gray-800 mt-6 mb-2">
                {parseBoldText(paragraph.replace('#### ', ''))}
              </h4>
            );
          }

          if (/^\d+\.\s/.test(paragraph)) {
            const items = paragraph.split('\n').filter(item => item.trim());
            return (
              <ol key={`${index}-${pIndex}`} className="list-decimal list-outside pl-6 space-y-2">
                {items.map((item, itemIndex) => (
                  <li key={itemIndex} className="text-gray-700">
                    {parseBoldText(item.replace(/^\d+\.\s/, ''))}
                  </li>
                ))}
              </ol>
            );
          }

          if (/^[-*]\s/.test(paragraph)) {
            const items = paragraph.split('\n').filter(item => item.trim());
            return (
              <ul key={`${index}-${pIndex}`} className="list-disc list-outside pl-6 space-y-2">
                {items.map((item, itemIndex) => (
                  <li key={itemIndex} className="text-gray-700">
                    {parseBoldText(item.replace(/^[-*]\s/, ''))}
                  </li>
                ))}
              </ul>
            );
          }

          return (
            <p key={`${index}-${pIndex}`} className="text-gray-700">
              {parseBoldText(paragraph)}
            </p>
          );
        });
      })}
    </div>
  );
};

export default ContentParser;