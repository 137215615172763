// components/Alert.js
import React from 'react';

// Alert Title Component
const AlertTitle = ({ children, className = '', ...props }) => {
  return (
    <h5 
      className={`font-medium mb-1 text-base ${className}`} 
      {...props}
    >
      {children}
    </h5>
  );
};

// Alert Description Component
const AlertDescription = ({ children, className = '', ...props }) => {
  return (
    <div 
      className={`text-sm ${className}`} 
      {...props}
    >
      {children}
    </div>
  );
};

// Main Alert Component
const Alert = ({ 
  children, 
  variant = 'default',
  title,
  action,
  onAction,
  currentTheme = 'default',
  className = '',
  ...props
}) => {
  const variants = {
    default: {
      light: 'bg-gray-100 border-gray-200 text-gray-800',
      dark: 'bg-slate-800 border-slate-700 text-slate-200'
    },
    destructive: {
      light: 'bg-red-100 border-red-200 text-red-800',
      dark: 'bg-red-900/50 border-red-800 text-red-200'
    },
    warning: {
      light: 'bg-yellow-100 border-yellow-200 text-yellow-800',
      dark: 'bg-yellow-900/50 border-yellow-800 text-yellow-200'
    },
    success: {
      light: 'bg-green-100 border-green-200 text-green-800',
      dark: 'bg-green-900/50 border-green-800 text-green-200'
    }
  };

  const isDark = currentTheme === 'modern';
  const colorScheme = isDark ? 'dark' : 'light';

  return (
    <div 
      className={`p-4 rounded-lg border ${variants[variant][colorScheme]} mb-4 ${className}`}
      {...props}
    >
      {children}
      {action && (
        <button
          onClick={onAction}
          className={`mt-2 px-3 py-1 text-sm font-medium rounded-md ${
            isDark 
              ? 'bg-slate-700 border-slate-600 text-slate-200 hover:bg-slate-600' 
              : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
          } border transition-colors duration-150`}
        >
          {action}
        </button>
      )}
    </div>
  );
};

// Add Title and Description as properties of Alert
Alert.Title = AlertTitle;
Alert.Description = AlertDescription;

export default Alert;