import React, { useState } from 'react';
import { RefreshCw } from 'lucide-react';
import Alert from './Alert';

const RegenerateSection = ({ 
  articleId, 
  h2Title,
  h3Title, 
  notes,
  currentTheme,
  onRegenerateComplete
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleRegenerate = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('/api/regenerate-h3-section', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          articleId,
          h2Title,
          h3Title,
          notes
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to regenerate section');
      }

      const data = await response.json();
      onRegenerateComplete(data.content);
    } catch (err) {
      setError(err.message);
      console.error('Regeneration error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const getThemeStyles = () => {
    switch (currentTheme) {
      case 'modern':
        return {
          button: 'text-slate-300 hover:text-emerald-400 hover:bg-slate-800',
          loadingSpinner: 'border-slate-600 border-t-emerald-400',
          errorAlert: 'bg-slate-800 border-red-500'
        };
      case 'generated':
        return {
          button: 'text-gray-600 hover:text-[#e85fb1] hover:bg-gray-50',
          loadingSpinner: 'border-gray-200 border-t-[#e85fb1]',
          errorAlert: 'bg-white/90 border-red-500'
        };
      default:
        return {
          button: 'text-gray-600 hover:text-blue-600 hover:bg-gray-50',
          loadingSpinner: 'border-gray-200 border-t-blue-600',
          errorAlert: 'bg-white border-red-500'
        };
    }
  };

  const themeStyles = getThemeStyles();

  return (
    <div className="relative">
      <button
        onClick={handleRegenerate}
        disabled={isLoading}
        className={`inline-flex items-center px-2 py-1 text-sm rounded-lg transition-colors duration-150 ${themeStyles.button}`}
        title="Regenerate this section"
      >
        <RefreshCw 
          className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} 
        />
        <span className="ml-1">再生成</span>
      </button>
      
      {error && (
        <Alert 
            className={`absolute z-50 top-full left-0 mt-2 w-64 ${themeStyles.errorAlert}`}
            currentTheme={currentTheme}
        >
            <Alert.Title className="text-xs text-red-500">
            {error}
            </Alert.Title>
        </Alert>
        )}
    </div>
  );
};

export default RegenerateSection;