import React, { useEffect, useState } from 'react';

const styles = `
  /* Reset and base styles */
  .article-container * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .article-container {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    line-height: 1.6;
    color: #1f2937;
    background-color: #f9fafb;
    -webkit-font-smoothing: antialiased;
    min-height: 100vh;
  }

  /* Layout */
  .article-container .container {
    max-width: 72rem;
    margin: 0 auto;
    padding: 0 1.5rem;
  }

  .article-container .content-area {
    padding: 8rem 0 5rem;
  }

  /* Typography */
  .article-container h1 {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: -0.037em;
    color: #111827;
    margin-bottom: 1rem;
  }

  .article-container h2 {
    font-size: 1.875rem;
    line-height: 1.3;
    font-weight: 600;
    letter-spacing: -0.037em;
    color: #111827;
    margin: 2.5rem 0 1.5rem;
  }

  /* Section overview styles */
  .article-container .section-overview {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 1rem;
    padding: 1.5rem;
    margin: 2rem 0;
    border: 1px solid rgba(229, 231, 235, 0.5);
    backdrop-filter: blur(4px);
  }

  .article-container .section-overview p {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 0.75rem;
  }

  .article-container .section-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: 0.5rem;
  }

  .article-container .section-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    background: white;
    border-radius: 0.75rem;
    color: #111827;
    text-decoration: none;
    transition: all 0.2s ease;
    border: 1px solid rgba(229, 231, 235, 0.8);
  }

  .article-container .section-link:hover {
    background: #f8fafc;
    border-color: rgba(59, 130, 246, 0.5);
    color: #3b82f6;
    transform: translateX(4px);
  }

  .article-container .section-link-arrow {
    width: 16px;
    height: 16px;
    opacity: 0.5;
    transition: opacity 0.2s;
  }

  .article-container .section-link:hover .section-link-arrow {
    opacity: 1;
    color: #3b82f6;
  }

  /* Smooth scroll behavior for anchor links */
  .article-container html {
    scroll-behavior: smooth;
  }

  /* Add padding-top to h3 elements for anchor links */
  .article-container h3 {
    scroll-margin-top: 2rem;
  }

  .article-container h3 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
    letter-spacing: -0.017em;
    color: #374151;
    margin: 2rem 0 1rem;
  }

  .article-container p {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    font-size: 1.125rem;
    line-height: 1.8;
    color: #374151;
    margin-bottom: 1.75rem;
    letter-spacing: -0.011em;
    font-feature-settings: "palt";
    text-align: justify;
    font-weight: 400;
    }

  .article-container section {
    scroll-margin-top: 100px;
  }

  /* First paragraph after h2 */
  .article-container h2 + p {
    font-size: 1.25rem;
    line-height: 1.7;
    color: #4B5563;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-weight: 400;
  }

  /* First paragraph after h3 */
  .article-container h3 + p {
    font-size: 1.125rem;
    line-height: 1.75;
    color: #4B5563;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  /* Paragraph with hanging punctuation for Japanese text */
  .article-container p {
    hanging-punctuation: allow-end;
    text-align: justify;
    text-justify: inter-character;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .article-container p {
      font-size: 1rem;
      line-height: 1.75;
    }
    
    .article-container h2 + p {
      font-size: 1.125rem;
    }
    
    .article-container h3 + p {
      font-size: 1rem;
    }
  }

  /* CTA Button Styles */
  .article-container .section-cta {
    margin: 3rem 0;
    padding: 2rem;
    background: linear-gradient(to right, rgba(59, 130, 246, 0.05), rgba(79, 70, 229, 0.05));
    border-radius: 1rem;
    text-align: center;
    border: 1px solid rgba(59, 130, 246, 0.1);
  }

  .article-container .cta-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 1rem 2rem;
    background: linear-gradient(to right, #3b82f6, #4f46e5);
    color: white;
    border-radius: 0.75rem;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.2);
  }

  .article-container .cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 10px -1px rgba(59, 130, 246, 0.3);
  }

  .article-container .cta-button:active {
    transform: translateY(0);
  }

  .article-container .cta-text {
    margin-bottom: 1.5rem;
    font-size: 1.125rem;
    color: #4B5563;
  }

  .article-container .cta-icon {
    width: 20px;
    height: 20px;
  }

  /* High-DPI screens */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .article-container p {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  /* Article header */
  .article-container .article-header {
    text-align: center;
    margin-bottom: 3rem;
  }

  .article-container .meta {
    font-size: 0.875rem;
    color: #6b7280;
  }

  /* Table of contents */
  .article-container .toc {
    position: relative;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 1rem;
    border: 1px solid rgba(229, 231, 235, 0.5);
    padding: 2rem;
    margin-bottom: 3rem;
    backdrop-filter: blur(4px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .article-container .toc::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: linear-gradient(to bottom, #3b82f6, #4f46e5);
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .article-container .toc-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    color: #111827;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .article-container .toc-icon {
    width: 24px;
    height: 24px;
    color: #3b82f6;
  }

  .article-container .toc-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .article-container .toc-item {
    margin: 0.5rem 0;
  }

  .article-container .toc-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    color: #4b5563;
    text-decoration: none;
    border-radius: 0.75rem;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .article-container .toc-link:hover {
    background-color: rgba(59, 130, 246, 0.05);
    color: #3b82f6;
  }

  .article-container .toc-link.active {
    background-color: rgba(59, 130, 246, 0.05);
    color: #3b82f6;
  }

  .article-container .toc-link-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .article-container .toc-arrow {
    width: 16px;
    height: 16px;
    opacity: 0.5;
    transition: opacity 0.2s;
  }

  .article-container .toc-link.active .toc-arrow {
    opacity: 1;
  }

  .article-container .toc-section-label {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    background-color: #f3f4f6;
    color: #6b7280;
  }

  /* Progress bar */
  .article-container .progress-container {
    margin-top: 1.5rem;
    height: 4px;
    background: #e5e7eb;
    border-radius: 2px;
    overflow: hidden;
  }

  .article-container .progress-bar {
    height: 100%;
    background: linear-gradient(to right, #3b82f6, #4f46e5);
    border-radius: 2px;
    transition: width 0.3s ease;
    width: 0%;
  }

  /* Reading time footer */
  .article-container .toc-footer {
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
    color: #6b7280;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  /* Images */
  .article-container .banner-container {
    margin: 2rem -1.5rem 3rem;
    position: relative;
    height: 400px;
    overflow: hidden;
  }

  @media (min-width: 640px) {
    .article-container .banner-container {
      margin: 2rem 0 3rem;
      border-radius: 1rem;
    }
  }

  .article-container .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .article-container .banner-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 4rem 1.5rem 1.5rem;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
    color: white;
  }

  .article-container .banner-credit {
    font-size: 0.875rem;
    opacity: 0.8;
  }
  
  .article-container .article-image {
    width: 100%;
    border-radius: 1rem;
    margin: 2rem 0;
  }

  /* Table styles */
  .article-container .table-container {
    margin: 2rem 0;
    overflow: hidden;
    border-radius: 1rem;
    background: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .article-container table {
    width: 100%;
    border-collapse: collapse;
  }

  .article-container th {
    text-align: left;
    padding: 1rem 1.5rem;
    background: #f9fafb;
    font-weight: 500;
    color: #374151;
    border-bottom: 1px solid #e5e7eb;
  }

  .article-container td {
    padding: 1rem 1.5rem;
    color: #4b5563;
    border-bottom: 1px solid #e5e7eb;
  }

  .article-container tr:last-child td {
    border-bottom: none;
  }

  /* FAQ section */
  .article-container .faq-container {
    margin: 2rem 0;
  }

  .article-container .faq-item {
    background: white;
    border-radius: 1rem;
    padding: 1.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .article-container .faq-question {
    font-weight: 600;
    color: #111827;
    margin-bottom: 0.5rem;
  }

  .article-container .faq-answer {
    color: #4b5563;
  }

  /* Responsive */
  @media (max-width: 768px) {
    .article-container .content-area {
      padding: 6rem 0 3rem;
    }

    .article-container h1 {
      font-size: 2rem;
    }

    .article-container h2 {
      font-size: 1.5rem;
    }

    .article-container h3 {
      font-size: 1.25rem;
    }

    .article-container .toc {
      padding: 1.5rem;
    }
  }
`;

const ArticleViewHTMLTEST = () => {
  const [activeSection, setActiveSection] = useState('intro');
  const HEADER_OFFSET = 100;

  useEffect(() => {
    // Create style element
    const styleEl = document.createElement('style');
    styleEl.innerHTML = styles;
    document.head.appendChild(styleEl);

    // Cleanup
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);

  useEffect(() => {
    // Scroll handling for Table of Contents
    const tocLinks = document.querySelectorAll('.article-container .toc-link');
    const sections = document.querySelectorAll('.article-container section');
    const progressBar = document.querySelector('.article-container .progress-bar');

    const updateToc = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 3;

      sections.forEach((section, index) => {
        const sectionTop = section.offsetTop;
        const sectionBottom = sectionTop + section.offsetHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          tocLinks.forEach(link => link.classList.remove('active'));
          tocLinks[index].classList.add('active');
        }
      });

      // Update progress bar
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight - windowHeight;
      const scrolled = window.scrollY;
      const progress = (scrolled / documentHeight) * 100;
      progressBar.style.width = `${Math.min(progress, 100)}%`;
    };

    // Initialize
    updateToc();

    // Add scroll listener
    window.addEventListener('scroll', updateToc);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', updateToc);
    };
  }, []);

  // Handle smooth scrolling
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - HEADER_OFFSET;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      setActiveSection(sectionId);
    }
  };

  // Navigation link component with proper accessibility
  const NavLink = ({ sectionId, label, icon }) => (
    <button 
      onClick={() => scrollToSection(sectionId)}
      className={`toc-link ${activeSection === sectionId ? 'active' : ''}`}
      aria-current={activeSection === sectionId ? 'true' : undefined}
    >
      <span className="toc-link-content">
        <svg className="toc-arrow" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" aria-hidden="true">
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
        {label}
      </span>
      {icon && <span className="toc-section-label">{icon}</span>}
    </button>
  );

  // Section link component with proper accessibility
  const SectionLink = ({ href, label }) => (
    <a 
      href={href}
      className="section-link"
      onClick={(e) => {
        e.preventDefault();
        scrollToSection(href.replace('#', ''));
      }}
    >
      {label}
      <svg 
        className="section-link-arrow" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2"
        aria-hidden="true"
      >
        <line x1="5" y1="12" x2="19" y2="12"></line>
        <polyline points="12 5 19 12 12 19"></polyline>
      </svg>
    </a>
  );

  return (
    <div className="article-container">
      <div className="container">
        <div className="content-area">
          <article>
            <header className="article-header">
              <h1>初めてのキャンプ用品：必需品完全ガイド</h1>
              <div className="meta" aria-label="記事の詳細">
                投稿日: 2024年3月15日 • 読了時間: 10分
              </div>
            </header>

            {/* Banner Image Section */}
            <div className="banner-container" role="img" aria-label="キャンプ場の夕景の写真">
              <img 
                src="/api/placeholder/1200/400" 
                alt=""  // Decorative image since we have the aria-label above
                className="banner-image"
              />
              <div className="banner-overlay">
                <div className="banner-credit">
                  Photo by Example Photographer
                </div>
              </div>
            </div>

            <nav className="toc" aria-label="目次">
              <div className="toc-title">
                <svg className="toc-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" aria-hidden="true">
                  <line x1="3" y1="12" x2="21" y2="12"></line>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg>
                目次
              </div>

              <ul className="toc-list">
                <li className="toc-item">
                  <NavLink sectionId="intro" label="キャンプ初心者の準備" icon="はじめに" />
                </li>
                <li className="toc-item">
                  <NavLink sectionId="essentials" label="キャンプの必需品リスト" icon="チェックリスト" />
                </li>
                <li className="toc-item">
                  <NavLink sectionId="equipment" label="キャンプ道具の選び方と使い方" icon="ガイド" />
                </li>
                <li className="toc-item">
                  <NavLink sectionId="skills" label="キャンプの基本スキル" icon="ハウツー" />
                </li>
                <li className="toc-item">
                  <NavLink sectionId="comparison" label="人気テント比較" icon="比較表" />
                </li>
                <li className="toc-item">
                  <NavLink sectionId="faq" label="よくある質問" icon="FAQ" />
                </li>
              </ul>

              <div className="progress-container" role="progressbar" aria-label="読書の進捗">
                <div className="progress-bar"></div>
              </div>

              <div className="toc-footer">
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" aria-hidden="true">
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
                読了時間 約10分
              </div>
            </nav>

            <section id="intro">
              <h2>キャンプ初心者の準備</h2>
              <p>キャンプは自然との触れ合いを通じて、心身ともにリフレッシュできる素晴らしいアウトドアアクティビティです。初めてのキャンプでも快適に過ごせるよう、必要な準備と基本的な知識をご紹介します。</p>
              <img src="/api/placeholder/800/400" alt="キャンプ風景" className="article-image" />
            </section>

            <section id="essentials">
              <h2>キャンプの必需品リスト</h2>
              <img 
                src="/api/placeholder/800/300" 
                alt="キャンプ必需品の展示" 
                className="article-image"
              />
              <div className="section-overview">
                <p>このセクションの内容：</p>
                <ul className="section-links">
                <li>
                    <SectionLink href="#basic-gear" label="基本装備" />
                  </li>
                  <li>
                    <SectionLink href="#comfort-items" label="快適性を高めるアイテム" />
                  </li>
                  <li>
                    <SectionLink href="#safety-items" label="安全対策アイテム" />
                  </li>
                </ul>
              </div>

              <p>快適なキャンプ生活のために、以下のアイテムは必ず用意しましょう。予算や使用頻度に応じて、購入するアイテムの優先順位を決めていくことをおすすめします。</p>

              <h3 id="basic-gear">基本装備</h3>
              <p>まずは基本的な装備から揃えていきましょう。以下のアイテムは、キャンプを楽しむための必須アイテムです。</p>

              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>カテゴリー</th>
                      <th>アイテム</th>
                      <th>重要度</th>
                      <th>予算目安</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>シェルター</td>
                      <td>テント、タープ</td>
                      <td>★★★</td>
                      <td>¥20,000〜</td>
                    </tr>
                    <tr>
                      <td>睡眠</td>
                      <td>寝袋、マット</td>
                      <td>★★★</td>
                      <td>¥15,000〜</td>
                    </tr>
                    <tr>
                      <td>照明</td>
                      <td>ランタン、ヘッドライト</td>
                      <td>★★★</td>
                      <td>¥5,000〜</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h3 id="comfort-items">快適性を高めるアイテム</h3>
              <p>基本装備に加えて、以下のアイテムがあれば、よりキャンプライフを快適に過ごすことができます。</p>

              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>アイテム</th>
                      <th>用途</th>
                      <th>優先度</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>キャンプチェア</td>
                      <td>長時間の滞在時の休憩用</td>
                      <td>★★☆</td>
                    </tr>
                    <tr>
                      <td>折りたたみテーブル</td>
                      <td>食事や作業スペース確保</td>
                      <td>★★☆</td>
                    </tr>
                    <tr>
                      <td>クーラーボックス</td>
                      <td>食材と飲み物の保管</td>
                      <td>★★★</td>
                    </tr>
                    <tr>
                      <td>パワーステーション</td>
                      <td>電源の確保</td>
                      <td>★☆☆</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <img 
                src="/api/placeholder/800/300" 
                alt="快適なキャンプサイトの様子" 
                className="article-image"
              />

              <h3 id="safety-items">安全対策アイテム</h3>
              <p>安全で安心なキャンプ生活のために、以下の安全対策アイテムも必ず用意しましょう。特に天候の急変や緊急時に備えた準備は重要です。</p>

              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>用途</th>
                      <th>必要なアイテム</th>
                      <th>使用シーン</th>
                      <th>重要度</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>応急手当</td>
                      <td>救急キット、常備薬</td>
                      <td>ケガや体調不良時</td>
                      <td>★★★</td>
                    </tr>
                    <tr>
                      <td>防災対策</td>
                      <td>防災ラジオ、予備電池</td>
                      <td>災害発生時</td>
                      <td>★★★</td>
                    </tr>
                    <tr>
                      <td>動物対策</td>
                      <td>虫除けスプレー、防虫ネット</td>
                      <td>虫や動物対策</td>
                      <td>★★☆</td>
                    </tr>
                    <tr>
                      <td>通信手段</td>
                      <td>携帯電話、予備バッテリー</td>
                      <td>緊急連絡時</td>
                      <td>★★★</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="section-cta">
                <p className="cta-text">必要なキャンプ用品をお探しですか？</p>
                <button className="cta-button">
                    <span>キャンプ用品を見る</span>
                    <svg className="cta-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <polyline points="12 5 19 12 12 19" />
                    </svg>
                </button>
                </div>
            </section>

            <section id="equipment">
              <h2>キャンプ道具の選び方と使い方</h2>
              <img 
                src="/api/placeholder/800/300" 
                alt="キャンプ道具の種類" 
                className="article-image"
              />
              <div className="section-overview">
                <p>このセクションの内容：</p>
                <ul className="section-links">
                  <li>
                    <SectionLink href="#lighting" label="照明器具の選択" />
                  </li>
                  <li>
                    <SectionLink href="#cooking" label="調理器具の基本" />
                  </li>
                </ul>
              </div>
              <p>キャンプ道具は安全で快適なアウトドアライフの要です。用途や予算に応じて、適切な道具を選びましょう。</p>

              <h3 id="lighting">照明器具の選択</h3>
              <p>夜間のキャンプ場で必要不可欠な照明器具。LEDランタン、ヘッドライト、懐中電灯など、用途に応じて使い分けることが重要です。メインの照明としてはLEDランタンがおすすめで、省電力で明るく、長時間の使用が可能です。</p>
              
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>照明の種類</th>
                      <th>用途</th>
                      <th>特徴</th>
                      <th>価格帯</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>LEDランタン</td>
                      <td>サイト全体の照明</td>
                      <td>省電力、長寿命</td>
                      <td>¥3,000〜</td>
                    </tr>
                    <tr>
                      <td>ヘッドライト</td>
                      <td>両手を使う作業時</td>
                      <td>ハンズフリー</td>
                      <td>¥2,000〜</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <h3 id="cooking">調理器具の基本</h3>
              <p>アウトドア調理の醍醐味を味わうためには、適切な調理器具の選択が重要です。シングルバーナー、クッカー、食器類など、最低限必要なアイテムから揃えていきましょう。特に火力の調整が容易なバーナーは、調理の幅を広げる重要なアイテムです。</p>

              <img src="/api/placeholder/800/400" alt="キャンプ調理器具の例" className="article-image" />
              <div className="section-cta">
                <p className="cta-text">道具の詳しい使い方を知りたいですか？</p>
                <button className="cta-button">
                    <span>使い方ガイドを見る</span>
                    <svg className="cta-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <polyline points="12 5 19 12 12 19" />
                    </svg>
                </button>
                </div>
            </section>

            <section id="skills">
                <h2>キャンプの基本スキル</h2>
                <img 
                src="/api/placeholder/800/300" 
                alt="キャンプスキルの実践" 
                className="article-image"
              />
              <div className="section-overview">
                <p>このセクションの内容：</p>
                <ul className="section-links">
                <li>
                    <SectionLink href="#tent-setup" label="テントの設営手順" />
                  </li>
                  <li>
                    <SectionLink href="#fire" label="火起こしとメンテナンス" />
                  </li>
                  <li>
                    <SectionLink href="#weather" label="天候の見方と対策" />
                  </li>
                </ul>
              </div>
                <p>快適なキャンプライフには、いくつかの基本的なスキルの習得が欠かせません。ここでは、初心者に特に重要なスキルをご紹介します。</p>

                <h3 id="tent-setup">テントの設営手順</h3>
                <p>テント設営は、キャンプの基本中の基本です。平らな場所を選び、風向きを考慮して設営することが重要です。以下の手順で効率的に設営できます：</p>
                <ul>
                  <li>設営場所の整地と確認</li>
                  <li>グランドシートの設置</li>
                  <li>テントの広げ方と方向決め</li>
                  <li>ポールの組み立てと挿入</li>
                  <li>ペグ打ちの位置と角度</li>
                </ul>

                <h3 id="fire">火起こしとメンテナンス</h3>
                <p>キャンプでの火起こしは、調理や暖を取るために重要なスキルです。安全で効率的な火起こしの方法と、火の管理方法を習得しましょう。</p>
                <div className="faq-item">
                  <h4 className="faq-question">重要なポイント</h4>
                  <p className="faq-answer">
                    • 風向きの確認と火の元の管理<br />
                    • 適切な薪の選択と組み方<br />
                    • 消火の手順と確認方法<br />
                    • 火器の安全な取り扱い
                  </p>
                </div>

                <h3 id="weather">天候の見方と対策</h3>
                <p>キャンプでは天候の変化に常に注意を払う必要があります。基本的な天気図の読み方や、急な天候変化への対応方法を知っておくことで、安全で快適なキャンプが楽しめます。</p>
                
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>天候の状況</th>
                        <th>必要な対策</th>
                        <th>準備するもの</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>晴れ</td>
                        <td>日差し対策</td>
                        <td>タープ、日焼け止め</td>
                      </tr>
                      <tr>
                        <td>雨</td>
                        <td>防水対策</td>
                        <td>レインウェア、防水シート</td>
                      </tr>
                      <tr>
                        <td>強風</td>
                        <td>飛散物対策</td>
                        <td>ペグ、ロープ、ウェイト</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="section-cta">
                    <p className="cta-text">より詳しいキャンプテクニックを学びたいですか？</p>
                    <button className="cta-button">
                        <span>キャンプガイドを見る</span>
                        <svg className="cta-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <polyline points="12 5 19 12 12 19" />
                        </svg>
                    </button>
                    </div>
            </section>

            <section id="comparison">
              <h2>人気テント比較</h2>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>モデル名</th>
                      <th>収容人数</th>
                      <th>重量</th>
                      <th>価格</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>コールマン ツーリングドーム</td>
                      <td>2人用</td>
                      <td>2.8kg</td>
                      <td>¥15,000</td>
                    </tr>
                    <tr>
                      <td>スノーピーク アメニティドーム</td>
                      <td>4人用</td>
                      <td>5.2kg</td>
                      <td>¥48,000</td>
                    </tr>
                    <tr>
                      <td>モンベル ムーンライト</td>
                      <td>3人用</td>
                      <td>3.4kg</td>
                      <td>¥32,000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section id="faq">
              <h2>よくある質問</h2>
              <div className="faq-container">
                <div className="faq-item">
                  <h3 className="faq-question">初心者にオススメのキャンプ場は？</h3>
                  <p className="faq-answer">設備が整った手ぶらキャンプ場やオートキャンプ場がおすすめです。トイレ、シャワー、電源などの設備が充実している場所を選びましょう。</p>
                </div>
                <div className="faq-item">
                  <h3 className="faq-question">テントは新品を買うべき？</h3>
                  <p className="faq-answer">初心者の場合、安全面を考慮して新品の購入をおすすめします。レンタルを利用して経験を積んでから購入するのも良い選択です。</p>
                </div>
                <div className="faq-item">
                  <h3 className="faq-question">雨対策は何が必要？</h3>
                  <p className="faq-answer">タープ、レインウェア、防水シート、防水スプレーなどの準備が必要です。天気予報をこまめにチェックすることも重要です。</p>
                </div>
              </div>
            </section>
          </article>
        </div>
      </div>
    </div>
  );
};

export default ArticleViewHTMLTEST;