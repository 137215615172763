import React, { useState } from 'react';
import { generateSectionImage } from '../api/articleApi';

const SectionImage = ({ articleId, sectionTitle, currentTheme, existingImage }) => {
  const [imageUrl, setImageUrl] = useState(existingImage?.url || null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleGenerateImage = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await generateSectionImage(articleId, sectionTitle);
      if (response.imageUrl) {
        setImageUrl(response.imageUrl);
      }
    } catch (err) {
      setError('Failed to generate image');
      console.error('Error generating image:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative mb-8">
      <div className="relative w-full h-64 rounded-lg shadow-md overflow-hidden">
        {isLoading ? (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <img 
            src={imageUrl || "/placeholder.png"}
            alt="Section illustration"
            className="w-full h-full object-cover"
            onError={(e) => {
              console.error('Failed to load image:', imageUrl);
              e.target.src = "/placeholder.png";
            }}
          />
        )}
        <button 
          onClick={handleGenerateImage}
          disabled={isLoading}
          className={`absolute bottom-4 right-4 px-4 py-2 ${
            currentTheme === 'modern' 
              ? 'bg-emerald-500 hover:bg-emerald-600' 
              : currentTheme === 'generated'
                ? 'bg-gradient-to-t from-gray-800 to-gray-700 hover:to-gray-600'
                : 'bg-blue-600 hover:bg-blue-700'
          } text-white rounded-lg shadow-md transition-colors duration-150 disabled:opacity-50`}
        >
          {isLoading ? 'Generating...' : imageUrl ? 'Regenerate image' : 'Generate image'}
        </button>
      </div>
      {error && (
        <div className="mt-2 text-red-500 text-sm">{error}</div>
      )}
    </div>
  );
};

export default SectionImage;