import React from 'react';
import PageIllustration from './PageIllustration';
import ContentParser from './ContentParser';

const TableOfContents = ({ article }) => {
  const createId = (title) => title.toLowerCase().replace(/\s+/g, '-');

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = window.innerWidth >= 768 ? 96 : 64;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="w-64 bg-white rounded-xl shadow-lg p-6 h-fit sticky top-24">
      <h2 className="text-lg font-bold mb-4 text-gray-900 blur-content">目次</h2>
      <nav className="space-y-1">
        {article.outline.H2Sections.map((section, index) => (
          <div key={index}>
            <a
              href={`#${createId(section.H2Title)}`}
              onClick={(e) => scrollToSection(e, createId(section.H2Title))}
              className="block py-2 px-3 text-sm text-gray-700 hover:bg-gray-100 rounded-md transition-colors duration-150 blur-content"
            >
              {section.H2Title}
            </a>
            <div className="ml-4 space-y-1">
              {section.H3Sections.map((subSection, subIndex) => (
                <a
                  key={subIndex}
                  href={`#${createId(subSection.H3Title)}`}
                  onClick={(e) => scrollToSection(e, createId(subSection.H3Title))}
                  className="block py-1 px-3 text-xs text-gray-600 hover:bg-gray-100 rounded-md transition-colors duration-150 blur-content"
                >
                  {subSection.H3Title}
                </a>
              ))}
            </div>
          </div>
        ))}
        <a
          href="#faq"
          onClick={(e) => scrollToSection(e, 'faq')}
          className="block py-2 px-3 text-sm text-gray-700 hover:bg-gray-100 rounded-md transition-colors duration-150 blur-content"
        >
          よくある質問
        </a>
      </nav>
    </div>
  );
};

const mockArticle = {
  title: "AIを活用した効果的な記事作成ガイド",
  intro: "現代のコンテンツ作成において、人工知能（AI）は強力な味方となっています。本記事では、AIを使って質の高い記事を効率的に作成する方法について詳しく解説します。",
  metadata: {
    createdAt: new Date('2024-03-15'),
    updatedAt: new Date('2024-03-15')
  },
  outline: {
    H2Sections: [
      {
        H2Title: "AIを使った記事作成の基礎",
        H3Sections: [
          {
            H3Title: "AIツールの選び方",
            note: "#### 主要なAIツール\n\n1. GPT-4ベースの記事作成支援\n2. 特化型AI執筆アシスタント\n3. リサーチ支援ツール\n\n**重要な選定基準**について詳しく見ていきましょう。",
            content: "効果的なAIツールの選択は、記事の質を大きく左右します。ツールの特徴や用途に応じて適切に選択することが重要です。"
          },
          {
            H3Title: "効果的なプロンプト設計",
            note: "#### プロンプトの基本\n\n- 明確な指示と目的の設定\n- 具体的な例の提供\n- 出力フォーマットの指定\n\n**プロンプトエンジニアリング**の重要性について解説します。",
            content: "プロンプトの設計は、AIから得られる出力の質を決定する重要な要素です。適切なプロンプト設計により、より質の高い記事を作成できます。"
          }
        ]
      },
      {
        H2Title: "記事構成の最適化",
        H3Sections: [
          {
            H3Title: "効果的なアウトライン作成",
            note: "#### アウトラインの要素\n\n1. 明確な目的設定\n2. ターゲット読者の定義\n3. キーメッセージの設定\n\n**構成の重要性**について詳しく解説します。",
            content: "アウトラインは記事の骨格となる重要な要素です。AIを活用することで、より効果的なアウトラインを作成できます。"
          },
          {
            H3Title: "SEO対策とキーワード選定",
            note: "#### SEO最適化の手順\n\n- キーワードリサーチの実施\n- 競合分析の活用\n- コンテンツ最適化\n\n**検索エンジン対策**の重要性について説明します。",
            content: "SEO対策は記事の検索エンジンでの表示順位に大きく影響します。AIを活用することで、効果的なSEO対策が可能になります。"
          }
        ]
      },
      {
        H2Title: "品質管理とレビュー",
        H3Sections: [
          {
            H3Title: "AI生成コンテンツのチェック",
            note: "#### 品質チェックポイント\n\n1. 事実確認と検証\n2. 文章の自然さ\n3. 一貫性のチェック\n\n**品質管理の重要性**について詳しく見ていきます。",
            content: "AI生成コンテンツの品質管理は非常に重要です。適切なチェックと修正により、より質の高いコンテンツを作成できます。"
          },
          {
            H3Title: "人間による最終レビュー",
            note: "#### レビューのポイント\n\n- 文脈の確認\n- 表現の適切性\n- ブランドトーンの統一\n\n**人間の役割**について解説します。",
            content: "最終的な記事の質を決定するのは人間のレビューです。AIと人間の協力により、より良い記事を作成できます。"
          }
        ]
      }
    ]
  },
  faq: [
    {
      question: "AIを使って記事を作成する際の注意点は？",
      answer: "AIを使用する際は、生成されたコンテンツの事実確認、著作権への配慮、そして人間による最終チェックが重要です。また、AIの特性を理解し、適切に活用することで、より効果的な記事作成が可能になります。"
    },
    {
      question: "AI記事作成にかかる時間は？",
      answer: "AIを活用することで、通常の記事作成時間を50-70%程度削減できます。ただし、質の高い記事を作成するためには、適切なプロンプト設計とレビュー時間の確保が必要です。"
    },
    {
      question: "AI生成コンテンツのSEO効果は？",
      answer: "適切に最適化されたAI生成コンテンツは、従来の手作業で作成したコンテンツと同等以上のSEO効果を発揮できます。ただし、キーワード選定や構成の最適化が重要です。"
    }
  ]
};

// Add CSS for blur effect
const styles = `
  .blur-content {
    color: transparent;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    user-select: none;
  }
  
  .blur-content-strong {
    color: transparent;
    text-shadow: 0 0 18px rgba(0,0,0,0.7);
    user-select: none;
  }
  
  .blur-box {
    position: relative;
    overflow: hidden;
  }
  
  .blur-box::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.1);
    backdrop-filter: blur(4px);
    pointer-events: none;
  }

  .image-placeholder {
    position: relative;
    background: linear-gradient(45deg, #f3f4f6 25%, #e5e7eb 25%, #e5e7eb 50%, #f3f4f6 50%, #f3f4f6 75%, #e5e7eb 75%, #e5e7eb 100%);
    background-size: 20px 20px;
    animation: move-bg 30s linear infinite;
  }

  @keyframes move-bg {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 400px 400px;
    }
  }
`;

const MyArticleDemo = () => {
  return (
    <section className="relative scroll-mt-24">
      <style>{styles}</style>
      <PageIllustration />
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Article Header */}
          <div className="text-center pb-12 md:pb-16">
            <h1 className="text-4xl md:text-5xl font-extrabold leading-tighter tracking-tighter mb-4 blur-content-strong">
              {mockArticle.title}
            </h1>
            <div className="text-sm text-gray-500 mb-8 blur-content">
              作成日: {mockArticle.metadata.createdAt.toLocaleDateString()}
            </div>
          </div>

          {/* Article image banner */}
          {/* <div className="mb-12 w-full h-96 rounded-xl shadow-lg overflow-hidden">
            <div className="image-placeholder w-full h-full blur-box" />
          </div> */}

          {/* Article Intro */}
          <div className="max-w-3xl mx-auto mb-12 text-center blur-box">
            <p className="text-xl text-gray-600 leading-relaxed blur-content">
              {mockArticle.intro}
            </p>
          </div>

          {/* Main Content with Sidebar */}
          <div className="flex gap-8">
            <TableOfContents article={mockArticle} />
            
            {/* Article Content */}
            <div className="flex-1 bg-white rounded-xl shadow-lg p-8">
              {mockArticle.outline.H2Sections.map((section, index) => (
                <div 
                  key={index} 
                  className="space-y-4 scroll-mt-24" 
                  id={section.H2Title.toLowerCase().replace(/\s+/g, '-')}
                >
                  <h2 className="text-2xl font-bold text-gray-900 blur-content">
                    {section.H2Title}
                  </h2>
                  
                  {section.H3Sections.map((subSection, subIndex) => (
                    <div 
                      key={subIndex} 
                      className="space-y-4 scroll-mt-24" 
                      id={subSection.H3Title.toLowerCase().replace(/\s+/g, '-')}
                    >
                      <h3 className="text-xl font-semibold text-gray-800 blur-content">
                        {subSection.H3Title}
                      </h3>
                      <div className="prose max-w-none blur-box">
                        <div className="blur-content">
                          <ContentParser text={subSection.note} />
                          {subSection.content && (
                            <ContentParser text={subSection.content} />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}

              {/* FAQ Section */}
              <div className="mt-12 scroll-mt-24" id="faq">
                <h2 className="text-2xl font-bold text-gray-900 mb-6 blur-content">
                  よくある質問
                </h2>
                <div className="space-y-6">
                  {mockArticle.faq.map((faq, index) => (
                    <div key={index} className="bg-gray-50 p-6 rounded-lg blur-box">
                      <h3 className="text-lg font-semibold text-gray-800 mb-2 blur-content">
                        {faq.question}
                      </h3>
                      <p className="text-gray-700 blur-content">{faq.answer}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Metadata */}
              <div className="mt-12 pt-6 border-t border-gray-200">
                <div className="flex justify-between text-sm text-gray-500 blur-content">
                  <div>
                    更新日: {mockArticle.metadata.updatedAt.toLocaleDateString()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyArticleDemo;