import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PageIllustration from './PageIllustration';
import { Pencil, Trash2, FileText, ChevronLeft, ChevronRight } from 'lucide-react';


function ArticleListNew() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 10;

  const fetchArticles = useCallback(async () => {
    try {
      // Get the authentication token from Clerk
      const token = await window.Clerk?.session?.getToken();
      console.log('Clerk Token:', token);
      
      if (!token) {
        console.error('No authentication token found');
        navigate('/sign-in');
        return;
      }
  
      console.log('Attempting to fetch articles');
      const response = await axios.get('/api/articles_new', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      console.log('Articles response:', response.data);
      
      setArticles(response.data);
      setTotalPages(Math.ceil(response.data.length / itemsPerPage));
      setLoading(false);
    } catch (error) {
      console.error('Full error details:', error);
      console.error('Error response:', error.response);
      
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
      }
      
      if (error.response && error.response.status === 401) {
        navigate('/sign-in');
      }
      setLoading(false);
    }
  }, [navigate, itemsPerPage]);
  
  useEffect(() => {
    fetchArticles();
  }, [fetchArticles, currentPage]);

  const handleDelete = async (id) => {
    if (window.confirm('この記事を削除してもよろしいですか？')) {
      try {
        await axios.delete(`/api/articles/${id}`);
        setArticles(articles.filter(article => article.id !== id));
        // Update total pages after deletion
        const remainingArticles = articles.filter(article => article.id !== id);
        setTotalPages(Math.ceil(remainingArticles.length / itemsPerPage));
        // If current page is now empty, go to previous page
        if (currentPage > Math.ceil(remainingArticles.length / itemsPerPage)) {
          setCurrentPage(prev => Math.max(prev - 1, 1));
        }
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    }
  };

  const getPaginatedArticles = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return articles.slice(startIndex, endIndex);
  };

  const goToPage = (page) => {
    setCurrentPage(Math.min(Math.max(1, page), totalPages));
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-800 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-6xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          <div className="mb-8 text-center">
            <h1 className="mb-4 text-3xl font-bold tracking-[-0.037em] text-gray-800 md:text-4xl lg:text-5xl">
              記事一覧
            </h1>
          </div>

          <div className="space-y-4">
            {getPaginatedArticles().map((article) => (
              <div
                key={article.id}
                className="overflow-hidden rounded-2xl bg-white/90 shadow-lg backdrop-blur-sm transition-all hover:shadow-xl"
              >
                <div className="flex flex-col gap-4 p-6 sm:flex-row sm:items-center sm:justify-between">
                  <div className="flex-1 cursor-pointer" onClick={() => navigate(`/my_articles/${article.id}`)}>
                    <h2 className="text-xl font-semibold text-gray-800 hover:text-gray-600">
                      {article.title}
                    </h2>
                    <div className="mt-2 text-sm text-gray-500">
                      {article.createdAt && `作成日: ${new Date(article.createdAt).toLocaleDateString('ja-JP')}`}
                    </div>
                  </div>
                  
                  <div className="flex flex-wrap items-center gap-2">
                    <button
                      onClick={() => navigate(`/outlines/${article.id}`)}
                      className="rounded-xl p-2 text-gray-600 transition-colors hover:bg-gray-100"
                      title="アウトライン"
                    >
                      <FileText size={20} />
                    </button>

                    <button
                      onClick={() => navigate(`/edit/${article.id}`)}
                      className="rounded-xl p-2 text-gray-600 transition-colors hover:bg-gray-100"
                      title="編集"
                    >
                      <Pencil size={20} />
                    </button>

                    <button
                      onClick={() => handleDelete(article.id)}
                      className="rounded-xl p-2 text-red-500 transition-colors hover:bg-red-50"
                      title="削除"
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            {articles.length === 0 && (
              <div className="rounded-2xl bg-white/90 p-8 text-center shadow-lg backdrop-blur-sm">
                <p className="text-gray-600">記事がありません</p>
              </div>
            )}

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex items-center justify-center gap-2 pt-6">
                <button
                  onClick={() => goToPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="inline-flex items-center rounded-xl bg-white px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50"
                >
                  <ChevronLeft size={16} className="mr-1" />
                  前へ
                </button>

                <div className="flex items-center gap-1">
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <button
                      key={page}
                      onClick={() => goToPage(page)}
                      className={`inline-flex h-8 w-8 items-center justify-center rounded-xl text-sm font-medium ${
                        currentPage === page
                          ? 'bg-gray-800 text-white'
                          : 'bg-white text-gray-800 hover:bg-gray-50'
                      }`}
                    >
                      {page}
                    </button>
                  ))}
                </div>

                <button
                  onClick={() => goToPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="inline-flex items-center rounded-xl bg-white px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50"
                >
                  次へ
                  <ChevronRight size={16} className="ml-1" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ArticleListNew;