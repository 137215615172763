import React from 'react';
import { SignUp } from '@clerk/clerk-react';
import MyArticleDemo from './MyArticleDemo';

const ArticleWithSignUp = () => {
  const clerkAppearance = {
    layout: {
      helpPageUrl: "https://clerk.dev/support",
      logoPlacement: "inside",
      privacyPageUrl: "https://clerk.dev/privacy",
      showOptionalFields: true,
      socialButtonsPlacement: "bottom",
      socialButtonsVariant: 'iconButton',
      termsPageUrl: "https://clerk.dev/terms",
    },
    elements: {
      rootBox: "mx-auto",
      card: "shadow-none p-0",
      header: "text-center",
      headerTitle: "text-2xl font-bold text-gray-900",
      headerSubtitle: "text-base text-gray-600",
      socialButtons: "flex flex-col gap-2",
      socialButtonsIconButton: "border border-gray-200 bg-white hover:bg-gray-50 text-gray-800",
      socialButtonsBlockButton: "border border-gray-200 bg-white hover:bg-gray-50 text-gray-800",
      dividerLine: "bg-gray-200",
      dividerText: "text-gray-500",
      formButtonPrimary: "bg-gray-800 hover:bg-gray-900 text-white shadow transition-colors",
      formFieldLabel: "text-gray-700 font-medium",
      formFieldInput: "rounded-xl border-gray-200 focus:border-gray-400 focus:ring-gray-400",
      formFieldInputShowPasswordButton: "text-gray-500 hover:text-gray-800",
      footerActionLink: "text-blue-600 hover:text-blue-800",
      identityPreviewEditButton: "text-blue-600 hover:text-blue-800",
      formResendCodeLink: "text-blue-600 hover:text-blue-800",
      alert: "rounded-xl bg-gray-50 border-gray-100 text-gray-600",
      alertText: "text-gray-600",
      avatarBox: "rounded-full",
      main: "w-full",
      form: "space-y-4",
      formField: "space-y-1",
      formFieldAction: "mt-1",
      formFieldHintText: "text-gray-500",
      formFieldErrorText: "text-red-600",
      identityPreview: "bg-gray-50 border border-gray-100 rounded-xl",
      identityPreviewText: "text-gray-600",
      otpCodeFieldInputs: "gap-2",
      otpCodeFieldInput: "rounded-lg border-gray-200 text-center",
      phoneInputBox: "rounded-xl border-gray-200",
      phoneInputInput: "rounded-xl border-gray-200",
      selectButton: "rounded-xl border border-gray-200 bg-white px-4 py-2 hover:bg-gray-50",
      selectButtonText: "text-gray-600",
    },
    variables: {
      borderRadius: '0.75rem',
      colorPrimary: '#1f2937',
      colorTextOnPrimary: '#ffffff',
      colorTextSecondary: '#4b5563',
      colorBackground: '#ffffff',
      colorInputBackground: '#ffffff',
      colorInputText: '#111827',
      colorSuccess: '#059669',
      colorDanger: '#dc2626',
      spacingUnit: '0.25rem',
    }
  };

  return (
    <div className="relative min-h-screen">
      {/* Background Article */}
      <div className="filter blur-[2px]">
        <MyArticleDemo />
      </div>

      {/* Overlay */}
      <div className="fixed inset-0 bg-gray-900/30 backdrop-blur-sm" />

      {/* Clerk Sign Up Container */}
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="bg-white rounded-2xl shadow-2xl p-4 md:p-8 max-w-md w-full mx-4">
          <SignUp 
            appearance={clerkAppearance}
            afterSignUpUrl="/welcome"
            redirectUrl="/welcome"
            signInUrl="/sign-in"
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleWithSignUp;